@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~react-toastify/dist/ReactToastify.min.css';

html {
  @apply text-fluid;
  @apply scroll-smooth;
}

body {
  @apply font-poppins;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-noto-serif;
  @apply text-teal-900;
}

:target {
  @apply pt-[7rem];
}
